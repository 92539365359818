export const getEmailFromText = (text: string) => {
  const matchFrom = text.match(/From: .*?<(.*?)>/i)
  if (matchFrom) {
    return matchFrom[1]
  }
  return undefined
}

export const getJobIdFromText = (text: string) => {
  const matchSubject = text.match(/((.*)(\r?\n\s+(.*))*)/i)
  if (matchSubject) {
    const subject = matchSubject[0].replace('\r', '').replace('\n', '')
    const matchJobId = subject.match(/(\d{4})/)
    if (matchJobId) {
      return matchJobId[0]
    }
  }
  return undefined
}
