import { useState } from 'react'
import { MonthPlacements } from './MonthPlacements'
import { PlacementWithCandidate } from './types'
import { capitalize, formatMonth, formatQuarter } from './utils'
import { IoCaretBack, IoCaretForward } from 'react-icons/io5'

type Props = {
  placements: PlacementWithCandidate[]
  type: string
  status: string[]
}

const STORAGE_PLACEMENTS_START_DATE = 'placements_start_date_'
const MIN_DATE = new Date(2023, 6, 1, -1, 0, 0)
const MAX_DATE = new Date(2030, 0, 1)

const getDefaultStartDate = (type: string) => {
  const value = localStorage.getItem(STORAGE_PLACEMENTS_START_DATE + type)
  if (value) {
    return new Date(Number(value))
  }
  const currentYear = new Date().getFullYear()
  return new Date(currentYear, 0, 1)
}

export const HalfYearTable = ({ placements, type, status }: Props) => {
  const [startDate, setStartDate] = useState(getDefaultStartDate(type))
  const dates: Date[] = []
  const startYear = startDate.getFullYear()
  const startMonth = startDate.getMonth()
  for (let i = startMonth; i < startMonth + 6; i++) {
    const d = new Date(startYear, i, 1)
    dates.push(d)
  }

  const onDateBack = () => {
    const newDate = new Date(startDate)
    newDate.setMonth(newDate.getMonth() - 6)
    setStartDate(newDate)
    localStorage.setItem(
      STORAGE_PLACEMENTS_START_DATE + type,
      String(newDate.getTime())
    )
  }

  const onDateForward = () => {
    const newDate = new Date(startDate)
    newDate.setMonth(newDate.getMonth() + 6)
    setStartDate(newDate)
    localStorage.setItem(
      STORAGE_PLACEMENTS_START_DATE + type,
      String(newDate.getTime())
    )
  }

  const filtered = placements.filter((placement) =>
    status.includes(placement.employmentType)
  )

  const canGoBackDate = () => {
    const newDate = new Date(startDate)
    newDate.setMonth(newDate.getMonth() - 6)
    return newDate > MIN_DATE
  }

  const goForwardDate = () => {
    return startDate < MAX_DATE
  }

  return (
    <div>
      <div className="flex flex-justify-between">
        <div className="placements-title">
          {capitalize(type)} Placements {formatQuarter(dates[0])} -{' '}
          {formatQuarter(dates[5])} {dates[0].getFullYear()}
        </div>
        <div className="flex gap-5">
          {canGoBackDate() && (
            <IoCaretBack onClick={() => onDateBack()} className="button" />
          )}
          {goForwardDate() && (
            <IoCaretForward
              onClick={() => onDateForward()}
              className="button"
            />
          )}
        </div>
      </div>
      <div className="table">
        <div className="flex table-row table-header">
          <div className="flex-1">Starters</div>
          <div className="flex-1">Stoppers</div>
          <div className="flex-1">Starters</div>
          <div className="flex-1">Stoppers</div>
        </div>
        <div className="flex table-row">
          <div className="flex-1 flex gap-5">
            <div style={{ width: 30 }}>{formatMonth(dates[0])}</div>
            <MonthPlacements
              placements={filtered}
              date={dates[0]}
              condition="start"
              type={type}
            />
          </div>
          <div className="flex-1">
            <MonthPlacements
              placements={filtered}
              date={dates[0]}
              condition="end"
              type={type}
            />
          </div>
          <div className="flex-1 flex gap-5">
            <div style={{ width: 30 }}>{formatMonth(dates[3])}</div>
            <MonthPlacements
              placements={filtered}
              date={dates[3]}
              condition="start"
              type={type}
            />
          </div>
          <div className="flex-1">
            <MonthPlacements
              placements={filtered}
              date={dates[3]}
              condition="end"
              type={type}
            />
          </div>
        </div>
        <div className="flex table-row mt-10">
          <div className="flex-1 flex gap-5">
            <div style={{ width: 30 }}>{formatMonth(dates[1])}</div>
            <MonthPlacements
              placements={filtered}
              date={dates[1]}
              condition="start"
              type={type}
            />
          </div>
          <div className="flex-1">
            <MonthPlacements
              placements={filtered}
              date={dates[1]}
              condition="end"
              type={type}
            />
          </div>
          <div className="flex-1 flex gap-5">
            <div style={{ width: 30 }}>{formatMonth(dates[4])}</div>
            <MonthPlacements
              placements={filtered}
              date={dates[4]}
              condition="start"
              type={type}
            />
          </div>
          <div className="flex-1">
            <MonthPlacements
              placements={filtered}
              date={dates[4]}
              condition="end"
              type={type}
            />
          </div>
        </div>
        <div className="flex table-row mt-10">
          <div className="flex-1 flex gap-5">
            <div style={{ width: 30 }}>{formatMonth(dates[2])}</div>
            <MonthPlacements
              placements={filtered}
              date={dates[2]}
              condition="start"
              type={type}
            />
          </div>
          <div className="flex-1">
            <MonthPlacements
              placements={filtered}
              date={dates[2]}
              condition="end"
              type={type}
            />
          </div>
          <div className="flex-1 flex gap-5">
            <div style={{ width: 30 }}>{formatMonth(dates[5])}</div>
            <MonthPlacements
              placements={filtered}
              date={dates[5]}
              condition="start"
              type={type}
            />
          </div>
          <div className="flex-1">
            <MonthPlacements
              placements={filtered}
              date={dates[5]}
              condition="end"
              type={type}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
