import { MS_IN_DAY } from './constants'
import { Job } from './types'

const isAscending = (sort: string) => sort === 'ASC'

export const asStr = (input: string | null) => (input === null ? '' : input)

export const compareText = (a: string, b: string, order: string) => {
  const aStr = asStr(a)
  const bStr = asStr(b)
  return isAscending(order)
    ? aStr.localeCompare(bStr)
    : -aStr.localeCompare(bStr)
}

export const compareNumber = (
  a: string | number,
  b: string | number,
  order: string
) => {
  return isAscending(order) ? Number(a) - Number(b) : Number(b) - Number(a)
}

export const compareDate = (a: string, b: string, order: string) => {
  return isAscending(order)
    ? new Date(a).getTime() - new Date(b).getTime()
    : new Date(b).getTime() - new Date(a).getTime()
}

export const daysUntilFirstCVSent = (job: Job) => {
  if (!job.InitialCVSendDate) {
    return null
  }
  const created = new Date(job.CreatedDate).getTime()
  const sent = new Date(job.InitialCVSendDate).getTime()
  return Math.floor((sent - created) / MS_IN_DAY)
}
