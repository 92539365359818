import { useEffect, useState } from 'react'
import { getApiUrl } from '../lib/dropbox_service'
import { PlacementWithCandidate } from './types'
import { MdOutlinePending } from 'react-icons/md'
import { TbProgressCheck } from 'react-icons/tb'
import { AiOutlineStop } from 'react-icons/ai'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import './Contractors.css'

const formatDate = (timestamp: number) => {
  return new Date(timestamp)
    .toISOString()
    .split('T')[0]
    .split('-')
    .reverse()
    .join('-')
}

type StatusIconProps = {
  status: string
}

const StatusIcon = ({ status }: StatusIconProps) => {
  switch (status) {
    case 'Submitted':
      return <MdOutlinePending color="#2d9ad0" title="Submitted" />
    case 'Onboarding':
      return <TbProgressCheck color="rgb(255, 121, 38)" title="Onboarding" />
    case 'Terminated':
      return <AiOutlineStop color="#BB1122" title="Terminated" />
  }
  return null
}

const flip = (order: string) => {
  if (order === 'DESC') {
    return 'ASC'
  }
  return 'DESC'
}

const STORAGE_CONTRACTORS_SORTING = 'contractors_sorting'

const getDefaultSortBy = () => {
  const contractorsSortBy = localStorage.getItem(STORAGE_CONTRACTORS_SORTING)
  if (contractorsSortBy) {
    return JSON.parse(contractorsSortBy)
  }
  return ['name', 'ASC']
}

const getLink = (contractor: PlacementWithCandidate) => {
  return `https://cls70.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${contractor.candidate.id}`
}

export const Contractors = () => {
  const [contractors, setContractors] = useState<PlacementWithCandidate[]>()
  const [sortBy, setSortBy] = useState(getDefaultSortBy())

  const loadContractors = async () => {
    const result = await fetch(getApiUrl() + '/placements')
    const json = ((await result.json()) as PlacementWithCandidate[]).sort(
      (a, b) => a.dateEnd - b.dateEnd
    )
    setContractors(json)
  }

  const setSorting = (column: string) => {
    let newSortBy
    if (column === sortBy[0]) {
      newSortBy = [column, flip(sortBy[1])]
    } else {
      newSortBy = [column, 'ASC']
    }
    setSortBy(newSortBy)
    localStorage.setItem(STORAGE_CONTRACTORS_SORTING, JSON.stringify(newSortBy))
  }

  useEffect(() => {
    loadContractors()
  }, [])

  const sorted = contractors?.sort((a, b) => {
    switch (sortBy[0]) {
      case 'dateEnd':
        return sortBy[1] === 'ASC'
          ? a.dateEnd - b.dateEnd
          : b.dateEnd - a.dateEnd
      case 'status':
        const statusDiff = a.status.localeCompare(b.status)
        return sortBy[1] === 'ASC' ? statusDiff : -statusDiff
      case 'name':
      default:
        const nameDiff = a.candidate.firstName.localeCompare(
          b.candidate.firstName
        )
        return sortBy[1] === 'ASC' ? nameDiff : -nameDiff
    }
  })

  const dateColWidth = 90
  const statusColWidth = 20

  return (
    <div className="contractors-container">
      <div className="contractors-title">Current Contracts</div>
      <div className="table">
        <div className="flex table-row table-header">
          <div className="flex-grow-4" onClick={() => setSorting('name')}>
            Contractor
          </div>
          <div
            style={{ width: dateColWidth }}
            onClick={() => setSorting('dateEnd')}
          >
            End date
          </div>
          <div
            style={{ width: statusColWidth }}
            onClick={() => setSorting('status')}
          >
            <IoMdInformationCircleOutline />
          </div>
        </div>
        {sorted &&
          sorted.map((contractor) => (
            <div key={contractor.id} className="flex table-row">
              <div className="flex-grow-4">
                <a href={getLink(contractor)} target="_blank">
                  {contractor.candidate.firstName}{' '}
                  {contractor.candidate.lastName}
                </a>
              </div>
              <div style={{ width: dateColWidth }}>
                <a href={getLink(contractor)} target="_blank">
                  {formatDate(contractor.dateEnd)}
                </a>
              </div>
              <div style={{ width: statusColWidth }}>
                <StatusIcon status={contractor.status} />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
