import { Commission } from './Commission'
import { PlacementWithCandidate } from './types'

type Props = {
  placements: PlacementWithCandidate[]
  date: Date
  condition: 'start' | 'end'
  type: string
}

const getDate = (
  placement: PlacementWithCandidate,
  condition: 'start' | 'end'
) => {
  if (condition === 'start') {
    return new Date(placement.dateBegin).getDate()
  }
  return new Date(placement.dateEnd).getDate()
}

const getLink = (placement: PlacementWithCandidate) => {
  return `https://cls70.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Placement&id=${placement.id}`
}

export const MonthPlacements = ({
  placements,
  date,
  condition,
  type,
}: Props) => {
  const values = placements.filter((v) => {
    const dateBegin = new Date(v.dateBegin)
    const dateEnd = new Date(v.dateEnd)
    switch (condition) {
      case 'start':
        return (
          dateBegin.getMonth() === date.getMonth() &&
          dateBegin.getFullYear() === date.getFullYear() &&
          v.isStarter
        )
      case 'end':
        return (
          dateEnd.getMonth() === date.getMonth() &&
          dateEnd.getFullYear() === date.getFullYear() &&
          ['Completed', 'Terminated'].includes(v.status) &&
          v.isStopper
        )
    }
  })

  return (
    <div>
      {values.map((v) => (
        <div key={v.id} className="flex">
          <Commission
            commission={v.commissions.data.find((com) => com.role === 'Sales')}
            type={type}
          />
          {type != 'Contract Management' && (
            <Commission
              commission={v.commissions.data.find((com) =>
                ['Recruiting', 'Resourcing'].includes(com.role)
              )}
              type={type}
            />
          )}
          <div className="ml-4">
            <a href={getLink(v)} target="_blank">
              {v.candidate.firstName} {v.candidate.lastName} (
              {getDate(v, condition)})
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}
