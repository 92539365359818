import { useEffect, useState } from 'react'
import { getApiUrl } from '../lib/dropbox_service'
import { PlacementWithCandidate } from './types'

import './Placements.css'
import { HalfYearTable } from './HalfYearTable'

export const Placements = () => {
  const search = new URLSearchParams(window.location.search)
  const tablesQuery = search.get('tables') || 'c'
  const tables = tablesQuery.split(',')
  const [placements, setPlacements] = useState<PlacementWithCandidate[]>([])

  const loadPlacements = async () => {
    const result = await fetch(
      getApiUrl() + '/placements?include_completed=true'
    )
    const json = ((await result.json()) as PlacementWithCandidate[]).sort(
      (a, b) => a.dateEnd - b.dateEnd
    )
    setPlacements(json)
  }

  useEffect(() => {
    loadPlacements()
  }, [])

  return (
    <div className="contractors-container">
      <div className="flex flex-col gap-15">
        {tables.includes('c') && (
          <HalfYearTable
            placements={placements}
            type="Contract"
            status={['Contract', 'Detavast', 'Permanent']}
          />
        )}
        {tables.includes('cm') && (
          <HalfYearTable
            placements={placements}
            type="Contract Management"
            status={['Contract Management']}
          />
        )}
      </div>
    </div>
  )
}
