export const NamesList = ({ list }: { list: string }) => {
  if (!list) {
    return null
  }
  const names = list.split(',')
  return (
    <>
      {names.map((name, idx) => {
        if (!Number.isNaN(parseInt(name))) {
          return (
            <span key={name} style={{ color: '#aaa' }}>
              {name}
              {idx < names.length - 1 ? ', ' : ''}
            </span>
          )
        }
        return (
          <span key={name}>
            {name}
            {idx < names.length - 1 ? ', ' : ''}
          </span>
        )
      })}
    </>
  )
}
