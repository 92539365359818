export const getApiUrl = () => {
  const isLocal = window.location.host.indexOf('localhost') !== -1
  return isLocal
    ? 'http://localhost:8003/jt-api'
    : 'https://intranet-tools.magno-it.nl/jt-api'
}

const config = {
  baseUrl: getApiUrl(),
}

export const getSessionId = (): string => {
  const cookies = document.cookie.split(';')
  const magnoJwt = cookies.find(
    (cookie) => cookie.trim().indexOf('MagnoToken') === 0
  )
  if (magnoJwt) {
    const jwt = magnoJwt.split('=', 2)[1]
    return jwt
  }
  return localStorage.getItem('sessionId') || ''
}

export const authedGet = async (path: string) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'X-authenticated': getSessionId(),
      Cookie: document.cookie,
    },
  })
  return result
}

const authedMutate = async (method: string, path: string, body: any) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method,
    headers: {
      'X-authenticated': getSessionId(),
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  })
  return result
}

export const authedPost = async (path: string, body: any) => {
  return await authedMutate('POST', path, body)
}

export const authedPut = async (path: string, body: any) => {
  return await authedMutate('PUT', path, body)
}
