import { useEffect, useState } from 'react'
import { authedGet, authedPost, authedPut } from '../lib/jobtracker_service'
import { Job } from './types'

type Props = {
  id: number
  onClose: (refresh: boolean) => void
}

export const EditJobModal = ({ id, onClose }: Props) => {
  const [job, setJob] = useState<Job>()

  const onLoadJob = async () => {
    const result = await authedGet(`jobs/${id}`)
    const json = await result.json()
    setJob({
      ...json,
      DeadlineDate: json.DeadlineDate?.split('T')[0],
      CreatedDate: json.CreatedDate.split('T')[0],
    })
  }

  const onSaveJob = async () => {
    if (job) {
      await authedPut(`jobs/${id}`, {
        ID: job.ID,
        ActiveRecruiter: job.ActiveRecruiter,
        DeadlineDate: job.DeadlineDate ? job.DeadlineDate + 'T12:00:00Z' : null,
        CreatedDate: job.CreatedDate + 'T12:00:00Z',
        DateQueryEshot: job.DateQueryEshot,
        ToDo: job.ToDo,
        Done: job.Done,
      })
    }
    onClose(true)
  }

  useEffect(() => {
    onLoadJob()
  }, [])

  if (!job) {
    return null
  }

  return (
    <div className="modal-overlay">
      <div className="jt-modal">
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <label style={{ marginBottom: 10 }}>
              Adapt ID:
              <div>{job.AdaptShortID}</div>
            </label>
            <label htmlFor="active_recruiter">
              Active Recruiter:
              <input
                id="active_recruiter"
                placeholder="Jonas Manneken"
                value={job.ActiveRecruiter}
                onChange={(e) =>
                  setJob({
                    ...job,
                    ActiveRecruiter: e.target.value,
                  })
                }
              />
            </label>
            <label htmlFor="deadline">
              Deadline:
              <input
                id="deadline"
                type="date"
                value={job.DeadlineDate || undefined}
                onChange={(e) =>
                  setJob({
                    ...job,
                    DeadlineDate: e.target.value,
                  })
                }
              />
            </label>
            <label htmlFor="createdDate">
              Created date:
              <input
                id="createdDate"
                type="date"
                value={job.CreatedDate}
                onChange={(e) =>
                  setJob({
                    ...job,
                    CreatedDate: e.target.value,
                  })
                }
              />
            </label>
          </div>
          <div style={{ flexGrow: 1 }} className="edit-job-right">
            <label htmlFor="query_eshot">
              Date &amp; query eshot:
              <textarea
                id="query_eshot"
                rows={5}
                cols={60}
                value={job.DateQueryEshot}
                onChange={(e) =>
                  setJob({
                    ...job,
                    DateQueryEshot: e.target.value,
                  })
                }
              />
            </label>
            <label htmlFor="to_do">
              To do:
              <textarea
                id="to_do"
                rows={5}
                cols={60}
                value={job.ToDo}
                onChange={(e) =>
                  setJob({
                    ...job,
                    ToDo: e.target.value,
                  })
                }
              />
            </label>
            <label htmlFor="done">
              Done:
              <textarea
                id="done"
                rows={5}
                cols={60}
                value={job.Done}
                onChange={(e) =>
                  setJob({
                    ...job,
                    Done: e.target.value,
                  })
                }
              />
            </label>
          </div>
        </div>
        <button
          style={{ marginRight: 15, cursor: 'pointer' }}
          onClick={onSaveJob}
        >
          Submit
        </button>
        <button style={{ cursor: 'pointer' }} onClick={() => onClose(false)}>
          Cancel
        </button>
      </div>
    </div>
  )
}
