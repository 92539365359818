import { useState } from 'react'
import { PlacementCommission } from './types'
import { RiStopFill } from 'react-icons/ri'

type Props = {
  commission?: PlacementCommission
  type: string
}

export const Commission = ({ commission, type }: Props) => {
  const [details, setDetails] = useState(false)
  if (!commission) {
    return null
  }
  let commission_role = commission.role
  if ((type = 'Contract Management')) {
    commission_role = 'Backoffice'
  }
  return (
    <div className="relative" style={{ paddingTop: 2 }}>
      <div
        className={`${details ? 'block' : 'hidden'} absolute tooltip`}
        style={{ left: 20, top: -20, width: 200 }}
      >
        {commission_role}: {commission.user.firstName}{' '}
        {commission.user.lastName}{' '}
      </div>
      <div
        className="flex"
        onMouseOver={() => setDetails(true)}
        onMouseOut={() => setDetails(false)}
      >
        <RiStopFill
          className="button"
          style={{ fill: commission.user.customText1 }}
        />
      </div>
    </div>
  )
}
