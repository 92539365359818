import { Route, Switch } from 'wouter'

import { Dropbox } from './dropbox/Dropbox'
import { JobTracker } from './job-tracker/JobTracker'
import { Contractors } from './contractors/Contractors'
import { Placements } from './placements/Placements'

export const IntranetTools = () => {
  return (
    <Switch>
      <Route path="/dropbox" component={Dropbox} />
      <Route path="/job-tracker" component={JobTracker} />
      <Route path="/contractors" component={Contractors} />
      <Route path="/placements" component={Placements} />
    </Switch>
  )
}
