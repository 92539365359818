export const MS_IN_DAY = 24 * 60 * 60 * 1000

export const FIELD_MAP: Record<string, string> = {
  Status: 'text',
  AdaptShortID: 'number',
  Role: 'text',
  AccountManagerName: 'text',
  ActiveRecruiter: 'text',
  CreatedDate: 'date',
  NewCandidates: 'number',
  ShortListedNames: 'text',
  CVsSubmittedNames: 'text',
  OnInterviewNames: 'text',
  CandidatePlacedNames: 'text',
  CandidateDeclinedOfferNames: 'text',
  DaysUntilFirstCVSent: 'number',
}
