import { useEffect, useMemo, useState } from 'react'
import { Job } from './types'

import './JobTracker.css'
import { AddJobModal } from './AddJobModal'
import { EditJobModal } from './EditJobModal'
import { SortFilter } from './SortFilter'
import { FIELD_MAP } from './constants'
import {
  asStr,
  compareDate,
  compareNumber,
  compareText,
  daysUntilFirstCVSent,
} from './helpers'
import { NamesList } from './NamesList'
import { getApiUrl } from '../lib/jobtracker_service'

type SortFilterType = [string, string]

const getSavedFilter = (): SortFilterType => {
  const filterName = window.localStorage.getItem('filterName') || ''
  const filterValue = window.localStorage.getItem('filterValue') || ''
  return [filterName, filterValue]
}

const getSavedSort = (): SortFilterType => {
  const sortName = window.localStorage.getItem('sortName') || ''
  const sortValue = window.localStorage.getItem('sortValue') || ''
  return [sortName, sortValue]
}

const setSavedFilter = (filter: SortFilterType) => {
  window.localStorage.setItem('filterName', filter[0])
  window.localStorage.setItem('filterValue', filter[1])
}

const setSavedSort = (sort: SortFilterType) => {
  window.localStorage.setItem('sortName', sort[0])
  window.localStorage.setItem('sortValue', sort[1])
}

export const JobTracker = () => {
  const [jobs, setJobs] = useState<Job[]>([])
  const [error, setError] = useState('')
  const [showAddJobModal, setShowAddJobModal] = useState(false)
  const [showEditJobModal, setShowEditJobModal] = useState<undefined | number>(
    undefined
  )
  const [sort, setSort] = useState<SortFilterType>(getSavedSort())
  const [filter, setFilter] = useState<SortFilterType>(getSavedFilter())
  const [showFilter, setShowFilter] = useState<string>('')

  const onSetFilter = (filter: SortFilterType) => {
    setFilter(filter)
    setSavedFilter(filter)
  }

  const onSetSort = (sort: SortFilterType) => {
    setSort(sort)
    setSavedSort(sort)
  }

  const loadJobs = async () => {
    const result = await fetch(getApiUrl() + '/jobs')
    const json = await result.json()
    if (json.error) {
      setError(json.error)
    } else {
      setJobs(
        json.map((job: any) => ({
          ...job,
          DaysUntilFirstCVSent: daysUntilFirstCVSent(job),
        }))
      )
    }
  }

  useEffect(() => {
    loadJobs()

    const documentClickHandler = () => {
      setShowFilter('')
    }

    window.document.addEventListener('click', documentClickHandler)
    return () => {
      window.document.removeEventListener('click', documentClickHandler)
    }
  }, [])

  const orderedjobs = useMemo(
    () =>
      jobs
        .sort((a: any /* Job */, b: any /* Job */) => {
          const type = FIELD_MAP[sort[0]]
          switch (type) {
            case 'text':
              return compareText(a[sort[0]], b[sort[0]], sort[1])
            case 'number':
              return compareNumber(a[sort[0]], b[sort[0]], sort[1])
            case 'date':
              return compareDate(a[sort[0]], b[sort[0]], sort[1])
          }
          return 0
        })
        .filter((a: any /* Job */) => {
          const type = FIELD_MAP[filter[0]]
          switch (type) {
            case 'text':
              return asStr(a[filter[0]])
                .toLowerCase()
                .includes(filter[1].toLowerCase())
            case 'number':
              return String(a[filter[0]])
                .toLowerCase()
                .includes(filter[1].toLowerCase())
            case 'date':
              return false
          }
          return true
        }),
    [jobs, sort, filter]
  )

  return (
    <div className="jt-container">
      {error && <div>Error: {error}</div>}
      {showAddJobModal && (
        <AddJobModal onClose={() => setShowAddJobModal(false)} />
      )}
      {showEditJobModal && (
        <EditJobModal
          id={showEditJobModal}
          onClose={(refresh: boolean) => {
            setShowEditJobModal(undefined)
            if (refresh) {
              loadJobs()
            }
          }}
        />
      )}
      <div className="top-btn">
        <button className="btn" onClick={() => setShowAddJobModal(true)}>
          Add job
        </button>
        <button className="btn" onClick={() => loadJobs()}>
          Refresh jobs
        </button>
        <div className="sort-filter-tips">
          {sort[0] && (
            <div className="sort-filter-tip">
              Sort by: {sort[0]}, {sort[1]}
              <div
                className="clear-sort-filter"
                onClick={() => onSetSort(['', ''])}
              >
                ☒
              </div>
            </div>
          )}
          {filter[0] && (
            <div className="sort-filter-tip">
              Filter on: {filter[0]}, {filter[1]}
              <div
                className="clear-sort-filter"
                onClick={(e) => {
                  e.stopPropagation()
                  onSetFilter(['', ''])
                }}
              >
                ☒
              </div>
            </div>
          )}
        </div>
      </div>
      <table className="jobs-table" cellSpacing={0} cellPadding={0}>
        <thead>
          <tr className="top-row">
            <td style={{ width: 160 }}>
              <SortFilter
                filter={filter}
                setFilter={onSetFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Status"
                value="Status"
              />
            </td>
            <td style={{ width: 95 }}>
              <SortFilter
                filter={filter}
                setFilter={onSetFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Adapt #"
                value="AdaptShortID"
              />
            </td>
            <td style={{ width: 280 }}>
              <SortFilter
                filter={filter}
                setFilter={onSetFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Role"
                value="Role"
              />
            </td>
            <td style={{ width: 150 }}>
              <SortFilter
                filter={filter}
                setFilter={onSetFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Account manager"
                value="AccountManagerName"
              />
            </td>
            <td style={{ width: 150 }}>
              <SortFilter
                filter={filter}
                setFilter={onSetFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Active recruiter"
                value="ActiveRecruiter"
              />
            </td>
            <td style={{ width: 130 }}>
              <SortFilter
                filter={filter}
                setFilter={onSetFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Created date"
                value="CreatedDate"
              />
            </td>
            <td style={{ width: 100 }}>
              <div className="sort-filter">Deadline</div>
            </td>
            <td style={{ width: 250 }}>
              <div className="sort-filter">Date &amp; query eshot</div>
            </td>
            <td style={{ width: 130 }}>
              <div className="sort-filter">To do</div>
            </td>
            <td style={{ width: 130 }}>
              <div className="sort-filter">Done</div>
            </td>
            <td style={{ width: 130 }}>
              <SortFilter
                setSort={onSetSort}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                sort={sort}
                title="New candidates"
                value="NewCandidates"
              />
              <span className="hidden">
                (the number of candidates in longlist who have no notes
                registered yet)
              </span>
            </td>
            <td style={{ width: 250 }}>
              <SortFilter
                filter={filter}
                setFilter={setFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Shortlisted"
                value="ShortListedNames"
              />
              <span className="hidden">(name candidates)</span>
            </td>
            <td style={{ width: 250 }}>
              <SortFilter
                filter={filter}
                setFilter={setFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="CVs submitted"
                value="CVsSubmittedNames"
              />
            </td>
            <td style={{ width: 250 }}>
              <SortFilter
                filter={filter}
                setFilter={setFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="On interview"
                value="OnInterviewNames"
              />
              <span className="hidden">(name candidates)</span>
            </td>
            <td style={{ width: 250 }}>
              <SortFilter
                filter={filter}
                setFilter={setFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Placed candidate"
                value="CandidatePlacedNames"
              />
              <span className="hidden">(name candidate)</span>
            </td>
            <td style={{ width: 250 }}>
              <SortFilter
                filter={filter}
                setFilter={setFilter}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                setSort={onSetSort}
                sort={sort}
                title="Declined offer"
                value="CandidateDeclinedOfferNames"
              />
              <span className="hidden">(name candidates)</span>
            </td>
            <td style={{ width: 200 }}>
              <SortFilter
                setSort={onSetSort}
                setShowFilter={setShowFilter}
                showFilter={showFilter}
                sort={sort}
                title="Days until first CV sent"
                value="DaysUntilFirstCVSent"
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {orderedjobs.map((job) => (
            <tr
              key={job.ID}
              style={{ cursor: 'pointer' }}
              onClick={() => setShowEditJobModal(job.ID)}
            >
              <td>{job.Status}</td>
              <td>
                <a
                  className="adapt-link"
                  target="_blank"
                  href={`https://p1.adaptondemand.com/WebApp/control-panel/controlpanel.html?domainID=10036&profileID=1728047688&domainName=MagnoIT&profileName=CoreProfileDelete&timeZone=Europe/Amsterdam&loginBo=7004916&logoutBo=-1&calendarEditBO=V8998012&uuid=89abc442-33f8-472e-89f3-caa5baaa8bea&originalTarget=control-panel%2Fcontrolpanel.html#/record/%7B%22id%22:${job.AdaptLongID},%22roleID%22:6920557,%22defaultValue%22:%225666%22,%22callerCode%22:%22115%22,%22selRoleIds%22:%226920557%22,%22callerType%22:%22FASTFIND%22,%22loadPrevSegm%22:true,%22listSessionKey%22:%22FASTFINDroot-MyAdaptQD6920557%22,%22bisQfSessionId%22:%22false%22%7D`}
                >
                  {job.AdaptShortID}
                </a>
              </td>
              <td>{job.Role}</td>
              <td>
                <NamesList list={job.AccountManagerName} />
              </td>
              <td>
                <NamesList list={job.ActiveRecruiter} />
              </td>
              <td>{job.CreatedDate.split('T')[0]}</td>
              <td>{job.DeadlineDate?.split('T')[0]}</td>
              <td>{job.DateQueryEshot}</td>
              <td>{job.ToDo}</td>
              <td>{job.Done}</td>
              <td>{job.NewCandidates}</td>
              <td>
                <NamesList list={job.ShortListedNames} />
              </td>
              <td>
                <NamesList list={job.CVsSubmittedNames} />
              </td>
              <td>
                <NamesList list={job.OnInterviewNames} />
              </td>
              <td>
                <NamesList list={job.CandidatePlacedNames} />
              </td>
              <td>
                <NamesList list={job.CandidateDeclinedOfferNames} />
              </td>
              <td>{job.DaysUntilFirstCVSent}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
