import { useState } from 'react'
import { authedPost } from '../lib/jobtracker_service'

import './AddJobModal.css'

type Props = {
  onClose: () => void
}

const URL_REGEX = /"id":(\d+),/
export const AddJobModal = ({ onClose }: Props) => {
  const [url, setUrl] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmitJob = async () => {
    const matches = decodeURIComponent(url).match(URL_REGEX)
    if (!matches) {
      return setMessage(
        'Invalid URL. Please ensure you copy the entirety of the URL from your browser address bar.'
      )
    }

    setLoading(true)
    try {
      const adaptLongId = matches[1]
      setMessage('Synchronising job from Adapt. Please wait...')
      const result = await authedPost(`jobs?adapt_id=${adaptLongId}`, {})
      console.log(result)
      setTimeout(() => {
        setLoading(false)
        window.location.reload()
      }, 1000)
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  return (
    <div className="modal-overlay">
      <div className="jt-modal">
        <p>
          To add a new job from Adapt, open the job in Adapt. Then copy the
          entire URL in the address bar and paste it here.
        </p>
        <label htmlFor="url">
          URL:
          <input
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </label>
        {message && (
          <div>
            <strong>{message}</strong>
          </div>
        )}
        <div className="add-modal-btns">
          <button
            style={{ marginRight: 15, cursor: 'pointer' }}
            disabled={!url || loading}
            onClick={() => onSubmitJob()}
          >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
