import { useState } from 'react'

import './SortFilter.css'

type Tuple = [string, string]

type Props = {
  filter?: Tuple
  title: string
  setFilter?: (filter: Tuple) => void
  setShowFilter: (filter: string) => void
  setSort: (sort: Tuple) => void
  showFilter: string
  sort: Tuple
  value: string
}

const invertSort = (sort: string) => {
  return sort === 'ASC' ? 'DESC' : 'ASC'
}

const focusNextRender = (id: string) => {
  setTimeout(() => document.getElementById(id)?.focus(), 0)
}

export const SortFilter = ({
  filter,
  title,
  setFilter,
  setShowFilter,
  setSort,
  showFilter,
  sort,
  value,
}: Props) => {
  const sortOnThis = value === sort[0]
  const sortOrder = sortOnThis ? invertSort(sort[1]) : 'ASC'
  const filterOnThis = filter && value === filter[0]
  const showThisFilter = setFilter && showFilter === value
  return (
    <div className="sort-filter">
      {title}
      {filter && setFilter && (
        <button
          className="filter-btn-icon"
          onClick={(e) => {
            e.stopPropagation()
            setShowFilter(value)
            focusNextRender(`filter_${value}`)
          }}
        >
          Ⴤ
        </button>
      )}
      <button onClick={() => setSort([value, sortOrder])}>⇅</button>
      {showThisFilter && (
        <div className="filter">
          <input
            id={`filter_${value}`}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setFilter([value, e.target.value])}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                setShowFilter('')
              }
            }}
            value={filterOnThis ? filter[1] : ''}
          />
        </div>
      )}
    </div>
  )
}
